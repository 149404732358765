<template>
    <div style="padding:20px;">
        <v-btn v-if="!addlocation" pa-4 pb-4 mp-4 small color="green" @click="addLocation()"
            style="color:#fff;margin-bottom:10px"> اضافه موقع ثاني</v-btn>
        <v-btn v-else pa-4 pb-4 mp-4 small color="green" @click="GetOwnerAddress()"
            style="color:#fff;margin-bottom:10px"> رجوع</v-btn>

        <v-row v-if="Addresses.length>1 && addlocation==false">
            <v-data-table :headers="headers" :items-per-page="25" :items="Addresses" class="elevation-1 request_table"
                style="width:96%" hide-default-footer="" loading-text="جاري تحميل البيانات">


                <!-- edit reservation -->
                <template v-slot:item.action="{ item }">
                    <v-btn text icon @click="editItem(item)">
                         <i class="fas fa-edit fa-lg"></i>
                    </v-btn>

                    <v-btn class="mx-2" text icon @click="delete_item(item.id)" dark>
                        <v-icon color="red">fas fa-trash</v-icon>
                    </v-btn>


                </template>
                <template v-slot:no-data>
                    <h4>لاتوجد بيانات</h4>
                </template>



            </v-data-table>
        </v-row>

        <v-row v-else>
    <v-card class="add_card add_card mb-12" style="width: 98%;" color="#cac0c057" height="auto" flat pr-4 pl-4 pt-4>

            <v-form ref="form" v-model="valid">
                <v-layout row wrap pr-2 pl-2>
                    <v-flex pr-3 xs12 md4 sm4>
                        <v-select background-color="#fff" height="50" item-value="id" item-text="province_name"
                            :items="province" placeholder=" المحافظة" target="#dropdown-example"
                            v-model="editedItem.province.id" required :rules="CitynameRules"></v-select>
                    </v-flex>
                    <v-flex pr-3 xs8>
                        <v-text-field background-color="#fff" placeholder=" الوصف " height="50"
                            v-model="editedItem.address_descraption" :rules="DescRules" required>
                        </v-text-field>
                    </v-flex>
                </v-layout>
                <v-flex xs12 v-if="map">
                    <GoogleMap @getmyLocation="getlocation" :latt="editedItem.lat" :long="editedItem.long"
                        @cancel="map_dialog=false" />
                </v-flex>
                <v-btn color="primary" @click="submit()">
                    <span v-if="editedIndex>=0">تعديل</span>
                    <span v-else>اضافه</span>
                </v-btn>
            </v-form>
        </v-card>
        </v-row>


    


    </div>
</template>

<script>
    import {
        EventBus
    } from '../event-bus.js';

    import GoogleMap from '../components/map'

    export default {

        data() {
            return {
                province: [],
                editedIndex: -1,
                map:false,
                addlocation: false,
                editedItem: {
                    id: '',

                    address_descraption: '',
                    province: {
                        id: '',
                        country_id: 1,

                    },


                    long: '',
                    lat: ''

                },


                CitynameRules: [
                    v => !!v || 'هذا الحقل مطلوب  ',

                ],
                DescRules: [
                    v => !!v || 'هذا الحقل مطلوب  ',

                ],
                Addresses: [],
                headers: [{
                        text: '#',
                        align: 'center',
                        sortable: false,
                        value: 'id',
                    },
                    {
                        text: 'المحافظه',
                        align: 'center',
                        sortable: false,
                        value: 'province.province_name',
                    },
                    {
                        text: 'الوصف',
                        align: 'center',
                        sortable: false,
                        value: 'address_descraption',
                    },

                    {
                        text: ' ',
                        value: 'action',
                        align: 'center',
                    },
                ],

            }
        },
        components: {

            GoogleMap

        },
        methods: {

            delete_item(id) {


        this.user_info = this.$cookies.get('admin_info');
        const Swal = require('sweetalert2')


        Swal.fire({
          title: "هل انت متاكد من الحذف ؟",

          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'نعم',
          cancelButtonText: 'خروج'

        }).then((result) => {
          if (result.value) {
            var url = "owner/v2/address/delete/" + id;
            this.$http({
              method: 'delete',
              url: url,
              headers: {}

            }).then(response => {
              response
              this.GetOwnerAddress();
                Swal.fire(
                'تم الحذف بنجاح',
                '',
                'success'
              )


            }).catch(error => {
              error
              this.$swal('  خطاء', "   يوجد حجز مرتبط بهاذا الموقع يجب تغير عنوانه اولاً", 'error')
            }).finally(d => {
              d,
            
              this.GetOwnerAddress();
            });



          }
        })



      },

            addLocation() {
                this.addlocation = true,
                    this.editedIndex = -1,
                    this.editedItem = {
                        id: '',

                        address_descraption: '',
                        province: {
                            id: '',
                            country_id: 1,

                        },


                        long: '',
                        lat: ''

                    }

            },

            submit() {
                if (this.$refs.form.validate()) {


                    var data = {

                        province_id: this.editedItem.province.id,
                        long: this.editedItem.long,
                        lat: this.editedItem.lat,
                        address_descraption: this.editedItem.address_descraption,


                    };

                    if (this.editedIndex > -1) {


                        var posturl = "owner/v2/address/update/" + this.editedItem.id;

                    } else {

                        posturl = "owner/v2/address/set";
                    }






                    this.loading = true;
                    this.$http({
                            method: 'post',
                            url: posturl,
                            data: data,
                            headers: {

                            }

                        })
                        .then(response => {


                            if (this.editedIndex > -1) {
                                this.$swal("تم تعديل الموقع", "", "success")



                            } else {
                                this.$swal("تم اضافه الموقع", "", "success")


                            }
                            this.Advertising_dialog = false;
                            this.$refs.form.reset();
                            this.GetOwnerAddress();

                            if (response.data.success == true) {
                                this.$refs.form.reset()
                                this.addlocation = false;


                            }
                            //



                        })
                        .catch(error => {
                            error
                        }).finally(d => {
                            d
                        });
                } else {


                    this.$swal('  خطاء', " يجب ملئ جميع الحقول   ", 'error');




                }

            },




            editItem(item) {
                this.addlocation = true
                this.editedItem = Object.assign({}, item)
                this.editedIndex = this.Addresses.indexOf(item)



            },

            getlocation(lat, lng) {

                this.editedItem.lat = lat;
                this.editedItem.long = lng


            },
            GetOwnerAddress() {
                this.addlocation = false,
                    this.$http({
                        method: 'get',
                        url: 'owner/v2/address/get',
                    }).then(response => {
                        response
                        this.Addresses = response.data.data.data,
                        this.editedItem = Object.assign({}, response.data.data.data[0])
                        this.editedIndex = this.Addresses.indexOf(response.data.data.data[0])
                        this.map=true;


                    }).catch(error => {
                        error
                    }).finally(d => {
                        d
                    });


            },
            getProvince() {


                this.$http({
                        method: 'get',
                        url: '/v2/info/Provinces',
                    })

                    .then(response => {
                        this.province = response.data.data;



                    })
            },
        },

        created() {

            EventBus.$on('getmyLocation', (c, f) => {
                alert('fd');
                alert(f);

            });

        },
        mounted() {
            this.GetOwnerAddress();
            this.getProvince();
        }

    }
</script>