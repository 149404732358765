<template>




   <div>
      <!-- <v-row justify="center" class="mb-6">
      <label style="margin-top:10px;font-size:22px;font-weight:bold">حدد موقع المركز</label>
    </v-row>
 -->



    <gmap-map id="googleMap" :center="center" :zoom="12" style="width:100%;  height: 350px;" @click.native="setPlace"
      :clickable="true" :draggable="true">


      <gmap-marker :key="index" v-for="(m, index) in markers" :position="m.position" @click="center=m.position"
        @drag="updateCoordinates" :clickable="true" :draggable="true" @g-click="center=m.center">
      </gmap-marker>


    </gmap-map>



   
        <v-layout pt-4 style="padding-top: 24px;padding-right: 24px;">

         <v-flex xs4 md4 sm4>

         </v-flex>

         <v-flex xs1 md4 sm4 >

         </v-flex>

          <v-flex xs1 md1 sm2>

         </v-flex>

         <v-flex xs6 md3 sm2>
            <v-btn color="primary" @click="mylocation()">

            <v-icon right="">fas fa-location-arrow</v-icon>
            <label class="map_btn"> تحديد موقعي الحالي
            </label>
          </v-btn>
         </v-flex>
          <!-- <v-spacer></v-spacer>
          <v-btn color="success" @click="getlocation"> <label class="map_btn"> اضافة</label></v-btn>
          <span style="width:10px"></span>
          <v-btn color="#ff0000" dark="" @click="cancelDailog"> <label class="map_btn">
              الغاء
            </label>
          </v-btn> -->

        </v-layout>

  

   </div>
</template>

<script>
  export default {
    props: {
      'latt': String,
      'long': String
    },
    name: "GoogleMap",
    data() {
      return {
        position: '',
        // default to Montreal to keep it simple
        // change this to whatever makes sense
        center: {
          lat: '',
          lng: ''
        },
        markers: [],
        places: [],
        lat: 0,
        lng: 0,
        currentPlace: null
      };
    },

    mounted() {


      if (this.latt == null || this.latt == '') {
   
        this.mylocation();

      } else {
     
          this.center = {
            lat:parseFloat(this.latt),
            lng:parseFloat(this.long)
          };
        this.addMarker(parseFloat(this.latt), parseFloat(this.long));
      }
     

    },

    methods: {


      mylocation() {

        this.hideAllMarkers();
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(position => {
            this.lat = position.coords.latitude;
            this.lng = position.coords.longitude;

            this.center = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
          this.$emit("getmyLocation",this.lat,this.lng);
          this.addMarker(this.lat, this.lng);

        })


    }
    else {
      alert("Your browser does not support Geolocation!");
    }
  },

  addMarker(lat, long) {
     

      const marker = {
        lat: lat,
        lng: long
      };

      this.markers.push({
        position: marker
      });


      this.places.push(this.currentPlace);

      this.center = marker;
      this.currentPlace = null;

    },

    updateCoordinates(location) {
      this.coordinates = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
      this.lat = location.latLng.lat();
      this.lng = location.latLng.lng();


      this.$emit("getmyLocation", location.latLng.lat(), location.latLng.lng());

    },

    setPlace(place) {
      this.currentPlace = place;



    },
    hideAllMarkers() {
      this.markers = [];
    },

    geolocate: function () {
      navigator.geolocation.getCurrentPosition(position => {

        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

      });


      this.$emit("getmyLocation", this.center.lat, this.center.lng);
    },
    getlocation() {
      this.$emit("cancel");

      this.$emit("getmyLocation", this.lat, this.lng);
    },
    cancelDailog() {
      this.$emit("cancel");
    }
  }
  };
</script>